import * as React from 'react'
import * as css from './Fields.module.css'
import { calculatorStore, YEARS_INCOME_NEEDED_OPTIONS } from './store'
import { Input } from './Inputs'
import { FootNote } from './FootNotes'
import CollegeCapital from './CollegeCapital'

export default function Fields() {
  const {
    // income
    totalAnnualIncome,
    setTotalAnnualIncome,
    otherAnnualIncome,
    setOtherAnnualIncome,
    yearsIncomeNeeded,
    setYearsIncomeNeeded,
    // expenses
    funeralExpenses,
    setFuneralExpenses,
    mortgage,
    setMortgage,
    collegeCapital,
    // assets
    savingsAndInvestments,
    setSavingsAndInvestments,
    retirementSavings,
    setRetirementSavings,
    presentAmount,
    setPresentAmount,
    // results
    setLifeInsuranceNeeded,
  } = calculatorStore()

  const capitalNeededForIncome =
    (totalAnnualIncome - otherAnnualIncome) * yearsIncomeNeeded

  const totalCollegeCapital =
    collegeCapital?.reduce(
      (acc, curr) => acc + curr.cost * Number(curr.yearsBeforeCollege),
      0,
    ) || 0

  const totalCapitalRequired = [
    capitalNeededForIncome,
    funeralExpenses,
    mortgage,
    totalCollegeCapital,
  ]
    .filter(Number)
    .reduce((acc, curr) => acc + curr, 0)

  const totalIncomeProducingAssets =
    savingsAndInvestments + retirementSavings + presentAmount

  React.useEffect(() => {
    setLifeInsuranceNeeded(totalCapitalRequired - totalIncomeProducingAssets)
  }, [totalCapitalRequired, totalIncomeProducingAssets])

  return (
    <section className={css.root}>
      <fieldset>
        <legend>Income</legend>
        <Input
          label="Total annual income your family would need if you died today"
          description={
            <p>
              What your family needs, before taxes, to maintain its current
              standard of living (Typically between 60% - 75% of total income)
            </p>
          }
          prefix="$"
          value={totalAnnualIncome}
          setter={setTotalAnnualIncome}
        />
        <Input
          label="Annual income your family would receive from other sources"
          description={
            <p>
              For example, spouse's earnings or a fixed pension{' '}
              <FootNote to="1" />. (Do not include income earned on your assets,
              as it is addressed later in the calculation)
            </p>
          }
          prefix="$"
          value={otherAnnualIncome}
          setter={setOtherAnnualIncome}
        />
        <Input
          label="Income to be replaced"
          prefix="$"
          output={totalAnnualIncome - otherAnnualIncome}
          inline
        />
        <Input
          label="Years income needed"
          options={YEARS_INCOME_NEEDED_OPTIONS}
          setter={setYearsIncomeNeeded}
          inline
        />
        <Input
          label="Capital needed for income"
          prefix="$"
          output={capitalNeededForIncome}
          inline
        />
      </fieldset>

      <fieldset>
        <legend>Expenses</legend>
        <Input
          label="Funeral and other final expenses"
          description={
            <p>Typically the greater of $15,000 or 4% of your estate</p>
          }
          prefix="$"
          value={funeralExpenses}
          setter={setFuneralExpenses}
        />
        <Input
          label="Mortgage and other outstanding debts"
          description={
            <p>
              Include mortgage balance, credit card balance, car loans, etc.
            </p>
          }
          prefix="$"
          value={mortgage}
          setter={setMortgage}
        />

        <CollegeCapital />

        <Input
          label="Total capital required"
          prefix="$"
          output={totalCapitalRequired}
          inline
        />
      </fieldset>

      <fieldset>
        <legend>Assets</legend>
        <Input
          label="Savings and investments"
          description={
            <p>
              Bank accounts, money market accounts,/ CDs, stocks, bonds, mutual
              funds, annuities, etc.
            </p>
          }
          prefix="$"
          value={savingsAndInvestments}
          setter={setSavingsAndInvestments}
        />
        <Input
          label="Retirement savings"
          description={
            <p>
              IRAs, 401 (k)s, SEP plans, SIMPLE IRA plas, Keoghs, pension and
              profit sharing plans <FootNote to="3" />
            </p>
          }
          prefix="$"
          value={retirementSavings}
          setter={setRetirementSavings}
        />
        <Input
          label="Present amount of life insurance"
          description={
            <p>
              Including group insurance as well as insurance purchased on your
              own
            </p>
          }
          prefix="$"
          value={presentAmount}
          setter={setPresentAmount}
        />
        <Input
          label="Total income producing assets"
          prefix="$"
          output={totalIncomeProducingAssets}
          inline
        />
      </fieldset>
    </section>
  )
}
