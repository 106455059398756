import * as React from 'react'
import * as css from './FootNotes.module.css'

const footnotes = [
  'Social Security benefits, which may be available, have not been factored into this calculation.',
  <>
    <i>Trends in College Pricing</i>, 2007, The College Board. Costs reflect
    total charges, which include tuition, fees, room and board.
  </>,
  'Distributions from most retirement savings plans are subject to ordinary income tax rates.',
]

export function FootNotesList() {
  return (
    <footer className={css.root}>
      <ul>
        {footnotes.map((footnote, i) => (
          <li id={`footnote-${i + 1}`} key={i}>
            <sup>
              <a className="link" href={`#footnote-${i + 1}-ref`}>
                {i + 1}
              </a>
            </sup>

            {footnote}
          </li>
        ))}
      </ul>
    </footer>
  )
}

export function FootNote({ to }) {
  return (
    <sup>
      <a id={`footnote-${to}-ref`} className="link" href={`#footnote-${to}`}>
        {to}
      </a>
    </sup>
  )
}
