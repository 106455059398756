import * as React from 'react'
import * as css from './LifeInsuranceCalculator.module.css'
import { calculatorStore } from './store'
import SanityBlock from 'components/common/SanityBlock'
import Fields from './Fields'
import clsx from 'clsx'
import { Input } from './Inputs'
import { FootNotesList } from './FootNotes'

export default function LifeInsuranceCalculator({ _rawContent }) {
  const { lifeInsuranceNeeded } = calculatorStore()

  return (
    <div className={clsx(css.root, 'padding container')}>
      {_rawContent && (
        <header className="richtext">
          <SanityBlock body={_rawContent} />
        </header>
      )}

      <div className={css.layout}>
        <Fields />

        <div className={css.results}>
          <Input
            label="Life insurance needed"
            prefix="$"
            output={lifeInsuranceNeeded}
          />
        </div>
      </div>

      <FootNotesList />
    </div>
  )
}
