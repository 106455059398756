import * as React from 'react'
import * as css from './ChartResults.module.css'
import ChartGrid from './ChartGrid'
import { Bar } from 'react-chartjs-2'
import { calculatorStore, formatPercentage, getCellData } from './store'

export default function TaxabaleEquiavalentChart() {
  const { deposit, annuityRate, taxRate, term } = calculatorStore()
  const [chartData, setChartData] = React.useState([0, 0])
  const [taxableYield, setTaxableYield] = React.useState(0)

  React.useEffect(() => {
    if (typeof document === 'undefined') return

    const TEY = getCellData('TEY')
    setChartData([annuityRate, TEY])
    setTaxableYield(TEY - annuityRate)

    return () => setChartData([annuityRate, 0])
  }, [deposit, annuityRate, taxRate, term])

  return (
    <ChartGrid
      title="Taxable Yield Required for Equivalent Return"
      chart={
        <Bar
          data={{
            labels: ['Tax-Deferred Annuity', 'Taxable Equivalent Yield'],
            datasets: [
              { data: chartData, backgroundColor: ['#00609e', '#74cef5'] },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                ticks: {
                  callback: (value) => formatPercentage(value, 2),
                },
              },
            },
          }}
          maintainAspectRatio={false}
          height={120}
        />
      }
    >
      <dl className={css.taxable}>
        <div>
          <dt className="h3">A Tax-Deferred Yield of:</dt>
          <dd className="h1">{formatPercentage(annuityRate, 2)}</dd>
        </div>
        <div className="h1">=</div>
        <div>
          <dt className="h3">A Taxable Yield of:</dt>
          <dd className="h1">
            {formatPercentage(annuityRate / (1 - taxRate / 100), 2)}
          </dd>
        </div>
      </dl>
    </ChartGrid>
  )
}
